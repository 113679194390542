import React, {Component} from 'react';
import 'react-dropdown/style.css';
import './App.scss';
import {
    ReactiveBase,
    CategorySearch,
    ReactiveComponent
} from '@appbaseio/reactivesearch';

let currentLangQuery;
let currentLang = 'SV'

currentLang = document.getElementsByTagName('html')[0].getAttribute('lang').substring(0,2).toUpperCase();

const priceFactor = document.getElementById('head')?.getAttribute('data-priceFactor') || 1;
const getTerms = document.getElementById('head')?.getAttribute('data-getTerms').split(",") || ['Använd - i nyskick', 'Ny - oanvänd', 'Använd - i bra skick', 'Använd - i använt skick'];

currentLangQuery = currentLang.toLowerCase();

if(currentLang === 'NB'){
    currentLangQuery = 'no';
}

const options = [
    {value: '_score', label: 'Bästa match'},
    {value: 'sort.raw', label: 'Pris: Låg till Hög'},
    {value: 'sort.raw', label: 'Pris: Hög till Låg'},
    {value: 'upload.raw', label: 'Nyaste'},
    {value: 'post_title.keyword', label: 'Produktnamn'},

]

class App extends Component {



    /**
     * Adds a badge to show type of result in dropdown
     *
     * @param value
     * @param object
     * @returns {string}
     */
    getHighlightField(value, object) {

        let key = Object.keys(object).find(key => object[key] === value);

        if (key === 'designer') {
            return this.strings['designer-label'];
        }

        if (key === 'designer_poplist') {
            return this.strings['designer-label'];
        }

        if (key === 'varumarke') {
            return  this.strings['brand-label'];
        }

        if (key === 'post_title') {
            return  this.strings['sort-name'];
        }

        if (key === 'underkategori_ngram') {
            return this.strings['subcategory-label'];
        }
        if (key === 'underkategori') {
            return this.strings['subcategory-label'];
        }
        if (key === 'huvudkategori') {
            return this.strings['category-label'];
        }

        return key;
    }

    // Change sort to best match
    setBestMatchFilterOnChange() {
        if (this.state.sortBy.value === '_score') {
            this.updateSorting({value: '_score', label: 'Bästa match'})
        }
    }

    // Set new sort field
    updateSorting(newSort) {
        console.log(newSort);
        this.setState({
            sortBy: newSort,

        });
    }

    toggleFilters(){
        this.setState({
            showFilters: !this.state.showFilters
        })
    };

    // Update number of results in state
    updateNumberOfResults(results) {
        this.setState({
            numberOfResults: results,
        });
    }

    constructor(props) {
        super(props);

        this.state = {
            numberOfResults: 0,
            sortBy: options[0],
            sortOrder: 'asc',
            total: 0,
            time: 0,
            language: 'SV',
            showFilters: false,
            selectValue: {value: '_score', label: 'Nyaste'}
        }

        if(currentLang === 'SV') {
            let searchString;

            if (document.body.classList.contains('single-product')) {
                searchString = 'Sök produkt';
            } else {
                searchString = 'Sök bland våra produkter';
            }
            this.strings = {
                'search-label': searchString,
                'search-short-label': 'Sök',
                'all-label': 'Alla',
                'brand-label': 'Varumärke',
                'color-label': 'Färg',
                'material-label': 'Material',
                'designer-label': 'Designer',
                'length-label': 'Längd',
                'width-label': 'Bredd/Djup',
                'height-label': 'Höjd',
                'category-label': 'Kategori',
                'subcategory-label': 'Underkategori',
                'enviroment-label': 'Miljömärkningar',
                'filter-label': 'Filter',
                'filter-classic': 'Rea',
                'sort-label': 'Sortering',
                'sort-standard': 'Standard',
                'sort-name': 'Produktnamn',
                'sort-new': 'Nyast',
                'sort-best': 'Bästa match',
                'search-in-prefix': 'Sök efter',
                'search-in-suffix': 'i alla kategorier'
            }
        }

        if(currentLang === 'EN') {
            this.strings = {
                'search-label': 'Search among our products',
                'search-short-label': 'Search',
                'all-label': 'All',
                'brand-label': 'Brand',
                'designer-label': 'Designer',
                'length-label': 'Length',
                'width-label': 'Width / Depth',
                'height-label': 'Height',
                'category-label': 'Category',
                'subcategory-label': 'Subcategory',
                'enviroment-label': 'Environmental labels',
                'filter-label': 'Filter',
                'filter-classic': 'Classic',
                'sort-label': 'Sort by',
                'sort-standard': 'Standard',
                'sort-name': 'Produktnamn',
                'sort-new': 'Newest',
                'sort-best': 'Best match',
                'search-in-prefix': 'Search for',
                'search-in-suffix': 'in all categories'
            }
        }

        if(currentLang === 'DA') {
            this.strings = {
                'search-label': 'Søg blandt vores produkter',
                'search-short-label': 'Søg',
                'all-label': 'Alle',
                'brand-label': 'Varemærke',
                'designer-label': 'Designer',
                'length-label': 'Længde',
                'width-label': 'Bredde/Dybde',
                'height-label': 'Højde',
                'category-label': 'Kategori',
                'subcategory-label': 'Underkategori',
                'enviroment-label': 'Miljømærker',
                'filter-label': 'Filter',
                'filter-classic': 'Klassiker',
                'sort-label': 'Sortering',
                'sort-standard': 'Standard',
                'sort-name': 'Produktnavn',
                'sort-new': 'Nyeste',
                'sort-best': 'Bedste match',
                'search-in-prefix': 'Søg efter',
                'search-in-suffix': 'i alle kategorier'
            }
        }


        if(currentLang === 'NB') {
            this.strings = {
                'search-label': 'Søk blant våre produkter',
                'search-short-label': 'Søk',
                'all-label': 'Alle',
                'brand-label': 'Varemerke',
                'designer-label': 'Designer',
                'length-label': 'Lengde',
                'width-label': 'Bredde / Dybde',
                'height-label': 'Høyde',
                'category-label': 'Kategori',
                'subcategory-label': 'Underkategori',
                'enviroment-label': 'Miljømerker',
                'filter-label': 'Filter',
                'filter-classic': 'Klassiker',
                'sort-label': 'Sortering',
                'sort-standard': 'Standard',
                'sort-name': 'Produktnavn',
                'sort-new': 'Nyeste',
                'sort-best': 'Beste treff',
                'search-in-prefix': 'Søk etter',
                'search-in-suffix': 'i alle kategorier'
            }
        }

        if(currentLang === 'FI') {
            this.strings = {
                'search-label': 'Etsi tuotteistamme',
                'search-short-label': 'Etsi',
                'all-label': 'Kaikki',
                'brand-label': 'Tuotemerkki',
                'designer-label': 'Suunnittelija',
                'length-label': 'Piitus',
                'width-label': 'Leveys / Syvyys',
                'height-label': 'Korkeus',
                'category-label': 'Kategoria',
                'subcategory-label': 'Alakategoria',
                'enviroment-label': 'Ympäristömerkinnät',
                'filter-label': 'Hakuehdot',
                'filter-classic': 'Klassikot',
                'sort-label': 'Hakutulos',
                'sort-standard': 'Standardi',
                'sort-name': 'Tuotteen nimi',
                'sort-new': 'Uusimmat',
                'sort-best': 'Bästa match',
                'search-in-prefix': 'Etsi',
                'search-in-suffix': 'kaikista kategorioista'
            }
        }
    }

    render() {
        return (
            <ReactiveBase
                app="greenified_products" url="https://cn-search.inputinterior.se/els/" credentials="elastic:7WsfWWFuStznNFmm"
            >
                <ReactiveComponent
                    componentId="languageFilter"
                    customQuery={props => ({
                        "query": {
                            "bool": {
                                "must": [
                                    {
                                        "term": {
                                            "post_status": 'publish',
                                        }
                                    },
                                    {
                                        "term": {
                                            "lang": currentLangQuery,
                                        }
                                    },
                                    {
                                        "terms": {
                                            "condition.keyword": getTerms
                                        }
                                    }
                                ]
                            }
                        },
                    })}

                />



                                    <div className="searcharea">
                                        <div className="searcharea-input">
                                            <CategorySearch
                                                componentId="SearchSensor"
                                                filterLabel={this.strings['search-short-label']}
                                                showClear={true}
                                                showDistinctSuggestions={false}
                                                dataField={['post_title', 'huvudkategori', 'underkategori', 'designer_poplist', 'varumarke']}
                                                categoryField="underkategori.keyword"
                                                showIcon={false}
                                                URLParams={true}
                                                fieldWeights={[1, 3, 3, 5, 10, 10]}
                                                fuzziness={0}
                                                placeholder={this.strings["search-label"]}
                                                react={{
                                                    and: ['languageFilter',
                                                        'categoryFilter',
                                                        'subCategoryFilter',
                                                        'makeFilter',
                                                        'materialFilter',
                                                        'colorFilter',
                                                        'brandFilter',
                                                        'conditionFilter',
                                                        'enviromentFilter']
                                                }}
                                                onValueChange={(value) => {
                                                    console.log("current value: ", value)
                                                    this.setBestMatchFilterOnChange();
                                                }}
                                                onValueSelected={
                                                    function(value, category, cause, source) {
                                                        if(value !== null){
                                                            if(value.category === null){
                                                                window.location.href = '/produkter/?SearchSensor="' + encodeURIComponent(value.term) +'"';
                                                            } else {
                                                                window.location.href = '/produkter/?SearchSensor=' + encodeURIComponent(JSON.stringify({
                                                                    category: value.category,
                                                                    value: value.term
                                                                }))
                                                            }
                                                        }
                                                    }
                                                }
                                                render={({
                                                             loading,
                                                             error,
                                                             value,
                                                             categories,
                                                             suggestions,
                                                             aggregationData,
                                                             downshiftProps: {isOpen, getItemProps, highlightedIndex},
                                                         }) => {
                                                    if (loading) {
                                                        return;
                                                    }
                                                    if (error) {
                                                        return <div>Something went wrong! Error
                                                            details {JSON.stringify(error)}</div>;
                                                    }
                                                    return isOpen && Boolean(value.length) ? (
                                                        <div className="search-suggestions">

                                                            {Boolean(value.length) && (
                                                                <div
                                                                    className={0 === highlightedIndex ? 'highlighted' : null}>

                                                                    <div {...getItemProps({
                                                                        item: {
                                                                            label: value,
                                                                            value: value
                                                                        }
                                                                    })}>
                                                                        {this.strings['search-in-prefix']} <strong>{value}</strong> {this.strings['search-in-suffix']}
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {suggestions.slice(0, 6).map((suggestion, indexfirst) => (
                                                                <div
                                                                    className={(indexfirst + 1) === highlightedIndex ? 'highlighted' : null}
                                                                    key={suggestion.value} {...getItemProps({item: suggestion})}>
                                                                     <span className="spacer"><span>
                                                                         {this.getHighlightField(suggestion.value, suggestion.source)}</span></span> {suggestion.value}
                                                                </div>
                                                            ))}
                                                            {categories.slice(0, 3).map((category, index) => (
                                                                <div
                                                                    className={((index + 1) + suggestions.slice(0, 6).length) === highlightedIndex ? 'highlighted category-view' : 'category-view'}
                                                                    key={category.key}
                                                                    {...getItemProps({
                                                                        item: {
                                                                            value: value,
                                                                            category: category.key
                                                                        }
                                                                    })}
                                                                >
                                                                    {value} i <strong>{category.key}</strong><span
                                                                    className="spacer"><span>{this.strings['search-category']}</span></span>
                                                                </div>
                                                            ))}


                                                        </div>
                                                    ) : null;
                                                }}
                                            />

                                        </div>
                                    </div>

            </ReactiveBase>
        );
    }
};

export default App;
